
import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { DeviceType, AppModule } from '@/store/modules/app'
import { AppMain, Navbar, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/resize'
import CreateClueDrawer from '@/drawers/create-clue/index.vue'
import UserApi from '@/api/user'
import UserStorage from '@/storages/user'

@Component({
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar,
    TagsView,
    CreateClueDrawer
  }
})
export default class extends mixins(ResizeMixin) {
  private logo = ''
  private width = ''
  private height = ''

  created() {
    this.getLogo()
  }

  private handleCommand(lang: string) {
    this.$i18n.locale = lang
    AppModule.SetLanguage(lang)
    document.documentElement.lang = lang
  }

  private async getLogo() {
    const { data } = await UserApi.getLogo()
    if (data) {
      this.logo = data.ecaasLogo
      this.width = data.ecaasLogoWidth
      this.height = data.ecaasLogoHeight
    }
  }

  private logout() {
    localStorage.clear()
    location.reload()
  }

  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    }
  }

  get userInfo() {
    return UserStorage.userInfo
  }

  private handleClickOutside() {
    AppModule.CloseSideBar(false)
  }
}
