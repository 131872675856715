
import { Component, Watch } from 'vue-property-decorator'
import { AppModule, ICreateClueDialogInfo } from '@/store/modules/app'
import AppService from '@/services/app'
import { IDictData, IProductData } from '@/api/types'
import ArrayUtil from '@/utils/array'
import { IContactData } from '@/constant/models'
import MarketingApi from '@/api/marketing'
import TextUtil from '@/utils/text'
import Constant from '@/constant/constant'
import CustomerApi from '@/api/customer'
import ContactInput from '@/components/ContactInput/index.vue'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'
import Events from '@/constant/events'
import { DpBackendChannelsChannelDto } from '@/proxy/models'
import { ChannelApi } from '@/proxy'

@Component({
  name: 'CreateClueDrawer',
  components: { ContactInput },
  computed: {
    showCreateClueDialog: {
      get: function() {
        return AppModule.createClueDialog.show
      },
      set: function() {
        AppModule.CloseCreateClueDialog()
      }
    }
  }
})
export default class extends mixins(ResetMixin) {
  private byProvider = false
  private customerName = ''
  private contactMethods: IDictData[] = []

  private contactList: IContactData[] = []

  private remarks = ''
  private createAndWaitAllocate = true
  private targetProductId = ''
  private productList: IProductData[] = []
  private channelId = ''
  private channelList: DpBackendChannelsChannelDto[] = []
  private clueDialogData !: ICreateClueDialogInfo

  @Watch('showCreateClueDialog', { immediate: true })
  private onVisibleChange(show: boolean) {
    if (show) {
      this.initData()
    }
  }

  initData() {
    this.clueDialogData = AppModule.createClueDialog
    this.contactList = []
    this.contactMethods = ArrayUtil.filter(AppService.ContactMethods?.dictItem)
    // 填充已有客户数据
    if (this.clueDialogData.contactWayTitles.length > 0) {
      this.clueDialogData.contactWays.forEach((way, index) => {
        this.contactList.push({
          value: way,
          dictId: this.clueDialogData.contactWayDictIds[index],
          methodName: this.clueDialogData.contactWayTitles[index]
        })
      })
    }
    // 无联系方式，初始化一个用于编辑
    if (this.contactMethods.length > 0 && this.contactList.length === 0) {
      this.contactList.push({
        value: '',
        dictId: this.contactMethods[0].id,
        methodName: this.contactMethods[0].name,
        isNew: true
      })
    }
    // 初始化客户姓名
    if (this.clueDialogData.customerName) {
      this.customerName = this.clueDialogData.customerName
    }
    this.getProductList()
    this.refreshChannelList()
  }

  private deleteContact(index: number) {
    this.contactList.splice(index, 1)
  }

  private addNewContact() {
    this.contactList.push({
      value: '',
      isEdit: true,
      isNew: true,
      dictId: this.contactMethods[0].id,
      methodName: this.contactMethods[0].name
    })
  }

  private async getProductList() {
    const { data } = await MarketingApi.productList()
    if (data) {
      this.productList = data
      if (this.productList.length > 0) {
        this.targetProductId = this.productList[0].id
      }
    }
  }

  private async refreshChannelList() {
    const { data } = await new ChannelApi().apiAppChannelSignedListGet()
    if (data) {
      this.channelList = data
    }
  }

  private async save(closeDialog = true) {
    if (TextUtil.isEmpty(AppModule.createClueDialog.source)) {
      this.$message.warning(Constant.Warnings.InvalidData)
      return
    }
    if (TextUtil.isEmpty(this.customerName)) {
      this.$message.warning(Constant.Warnings.CreateClueInvalidName)
      return
    }
    if (this.contactList.length === 0) {
      this.$message.warning(Constant.Warnings.CreateClueInvalidContact)
      return
    }
    if (this.byProvider && !this.channelId) {
      this.$message.warning(Constant.Warnings.CreateClueInvalidProvider)
      return
    }
    let valid = true
    this.contactList.forEach(contact => {
      if (contact.error) {
        valid = false
      }
    })
    if (!valid) {
      return
    }
    const { status } = await CustomerApi.createClue(
      this.byProvider
        ? Constant.ClueSourceType.BackendNewByChannel.value
        : AppModule.createClueDialog.source,
      AppModule.createClueDialog.customerId,
      this.customerName,
      this.targetProductId,
      this.remarks,
      this.createAndWaitAllocate,
      this.contactList,
      this.channelId
    )
    if (status !== 204) {
      return
    }
    this.$EventBus.$emit(Events.CREATE_CLUE)
    if (closeDialog) {
      AppModule.CloseCreateClueDialog()
      return
    }
    this.$message.success('新建线索成功')
    this.resetData()
  }
}
